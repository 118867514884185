<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <p class="project-header">เพิ่มนักศึกษาของรายวิชา {{ this.course.type_name }} ({{ this.course.program_code }})  ปีการศึกษา {{ this.course.year }}</p>

        <!-- Student File -->
        <div class="form-div mt-8">  
          <p class="label my-auto -ml-4">รายชื่อนักศึกษา</p>
          <div class="upload-button">
            <label id="file-name" class="mr-8">ยังไม่มีการอัปโหลดไฟล์</label>
            <label for="students" class="file-button">อัปโหลด</label>
            <input @change="handleFileUpload($event)" type="file" ref="students" id="students" name="students" accept=".csv" class="w-0" required>
            <small class="mx-4">(รองรับเฉพาะไฟล์ .csv เท่านั้น)</small>
            <small class="cursor-pointer" style="color: blue"><u @click="download()">ตัวอย่างไฟล์รายชื่อนักศึกษา</u></small>
          </div>
        </div>

        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">บันทึกข้อมูล</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { changeTypeName } from "../mixins/other"

export default {
  name: "StudentAdd",
  components: {
    NavBar,
  },
  data () {
    return {
      loading: true,
      isSubmitted: false,
      course: {
        type_name: ""
      },
      role: null,
    }
  },
  async created() {
    document.title = "เพิ่มรายชื่อนักศึกษา | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== 'STF') {
      this.$router.push("/")
    }
    this.course = await this.$store.dispatch("getCourseById", this.$route.params.cid)
    this.course_id = this.course.prev_course != null ? this.course.prev_course : this.course.id
    this.course.type_name = changeTypeName(this.course, '')
    this.loading = false
  },
  methods: {
    handleFileUpload(event) {
      document.getElementById("file-name").innerHTML = "ยังไม่มีการอัปโหลดไฟล์"
      this.file = event.target.files[0]
      document.getElementById("file-name").innerHTML = event.target.files[0].name
    },
    async download() {
      await this.$store.dispatch("downloadDocumentFile", { id: 1, name: "ไฟล์รายชื่อนักศึกษา.csv", type: "document" })
    },
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        const fileData = { id: this.course_id, file: this.file }
        try{
          await this.$store.dispatch("updateStudent", fileData)
          this.$router.push(`/course/${this.$route.params.cid}/student/`)
        } catch (e) {
          alert(e.response.data)
        }
        this.isSubmitted = false
        this.loading = false
      }
    }
  }
}
</script>
